// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-projects-1-discrimination-mdx": () => import("./../../../src/projects/1discrimination.mdx" /* webpackChunkName: "component---src-projects-1-discrimination-mdx" */),
  "component---src-projects-10-disgust-mdx": () => import("./../../../src/projects/10disgust.mdx" /* webpackChunkName: "component---src-projects-10-disgust-mdx" */),
  "component---src-projects-11-climate-mdx": () => import("./../../../src/projects/11climate.mdx" /* webpackChunkName: "component---src-projects-11-climate-mdx" */),
  "component---src-projects-12-exploitation-mdx": () => import("./../../../src/projects/12exploitation.mdx" /* webpackChunkName: "component---src-projects-12-exploitation-mdx" */),
  "component---src-projects-13-petfam-mdx": () => import("./../../../src/projects/13petfam.mdx" /* webpackChunkName: "component---src-projects-13-petfam-mdx" */),
  "component---src-projects-14-mobility-mdx": () => import("./../../../src/projects/14mobility.mdx" /* webpackChunkName: "component---src-projects-14-mobility-mdx" */),
  "component---src-projects-15-isolation-mdx": () => import("./../../../src/projects/15isolation.mdx" /* webpackChunkName: "component---src-projects-15-isolation-mdx" */),
  "component---src-projects-16-seniorcitizen-mdx": () => import("./../../../src/projects/16seniorcitizen.mdx" /* webpackChunkName: "component---src-projects-16-seniorcitizen-mdx" */),
  "component---src-projects-17-disaster-mdx": () => import("./../../../src/projects/17disaster.mdx" /* webpackChunkName: "component---src-projects-17-disaster-mdx" */),
  "component---src-projects-2-discrimination-mdx": () => import("./../../../src/projects/2discrimination.mdx" /* webpackChunkName: "component---src-projects-2-discrimination-mdx" */),
  "component---src-projects-3-most-conflict-mdx": () => import("./../../../src/projects/3most-conflict.mdx" /* webpackChunkName: "component---src-projects-3-most-conflict-mdx" */),
  "component---src-projects-4-most-conflict-work-mdx": () => import("./../../../src/projects/4most-conflict-work.mdx" /* webpackChunkName: "component---src-projects-4-most-conflict-work-mdx" */),
  "component---src-projects-5-abortion-mdx": () => import("./../../../src/projects/5abortion.mdx" /* webpackChunkName: "component---src-projects-5-abortion-mdx" */),
  "component---src-projects-6-euthanasia-mdx": () => import("./../../../src/projects/6euthanasia.mdx" /* webpackChunkName: "component---src-projects-6-euthanasia-mdx" */),
  "component---src-projects-7-prostitution-youth-mdx": () => import("./../../../src/projects/7prostitution-youth.mdx" /* webpackChunkName: "component---src-projects-7-prostitution-youth-mdx" */),
  "component---src-projects-8-swamp-news-mdx": () => import("./../../../src/projects/8swamp-news.mdx" /* webpackChunkName: "component---src-projects-8-swamp-news-mdx" */),
  "component---src-projects-9-liberal-arts-question-mdx": () => import("./../../../src/projects/9liberal-arts-question.mdx" /* webpackChunkName: "component---src-projects-9-liberal-arts-question-mdx" */),
  "component---src-projects-chart-base-js": () => import("./../../../src/projects/ChartBase.js" /* webpackChunkName: "component---src-projects-chart-base-js" */),
  "component---src-templates-project-details-jsx": () => import("./../../../src/templates/project-details.jsx" /* webpackChunkName: "component---src-templates-project-details-jsx" */),
  "component---src-templates-tags-jsx": () => import("./../../../src/templates/tags.jsx" /* webpackChunkName: "component---src-templates-tags-jsx" */)
}

