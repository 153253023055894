export const palette = [
  '#B60091',
  '#ffa600',
  '#f40063',
  '#291ab0',
  '#ff3d44',
  '#4B4FE3',
  '#9028FF',
  '#ff5c35',
  '#5FAFFA',
  '#4BE3E2',
  '#cf0082',
]
